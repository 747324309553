import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import ChatBubble from '../../src/Icons/ChatBubble';
import Location from '../../src/Icons/Location';
import Phone from '../../src/Icons/Phone';
import Email from '../../src/Icons/Email';
import CheckmarkOutline from '../../src/Icons/CheckmarkOutline';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from '@mfrm/mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <ChatBubble width={30} height={30} />\n  <Location width={30} height={30} />\n  <Phone width={30} height={30} />\n  <Email width={30} height={30} />\n  <CheckmarkOutline width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      ChatBubble,
      Location,
      Phone,
      Email,
      CheckmarkOutline,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <ChatBubble width={30} height={30} mdxType="ChatBubble" />
    <Location width={30} height={30} mdxType="Location" />
    <Phone width={30} height={30} mdxType="Phone" />
    <Email width={30} height={30} mdxType="Email" />
    <CheckmarkOutline width={30} height={30} mdxType="CheckmarkOutline" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      